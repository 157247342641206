import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Recipe } from 'api/Recipe';
import defaultImage from 'assets/images/default-image.jpg';
import s from './RecipeCard.module.css';
import { useNavigate } from 'react-router-dom';
import { Category } from 'api/data/categories';

const RECIPE_MAX_TITLE_LENGTH = 24;

const getCategories = (categories: Category[] | undefined) => {
  if (!categories?.length) {
    return '';
  }
  if (categories.length > 3) {
    return categories.slice(0, 3).join(', ') + '...';
  }
  return categories.join(', ');
};

interface Props {
  recipe: Recipe;
}

export const RecipeCard = ({ recipe }: Props) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{ maxWidth: 345, cursor: 'pointer' }}
      onClick={() => navigate(`/recipes/${recipe._id}`)}
    >
      <CardHeader
        title={
          <Button variant="text" style={{ fontSize: '20px', padding: '0 5px', marginLeft: '-5px' }}>
            {recipe.name.length > RECIPE_MAX_TITLE_LENGTH
              ? recipe.name.slice(0, RECIPE_MAX_TITLE_LENGTH) + '...'
              : recipe.name}
          </Button>
        }
        classes={{
          subheader: s.subheader,
        }}
      />
      <CardMedia
        component="img"
        height="194"
        image={recipe.imageUrl ? recipe.imageUrl : defaultImage}
        alt={recipe.name}
      />
      <CardContent classes={{ root: s.cardContent }}>
        {getCategories(recipe.categories)}
      </CardContent>
      <CardActions disableSpacing>
        {recipe.cookTime && (
          <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
            <AccessTimeIcon sx={{ marginRight: '5px' }} />
            <p>{recipe.cookTime}</p>
          </Box>
        )}
        {recipe.servings && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PeopleIcon sx={{ marginRight: '5px' }} />
            <p>{recipe.servings}</p>
          </Box>
        )}
      </CardActions>
    </Card>
  );
};
