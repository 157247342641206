import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { Recipes } from 'routes/Recipes';
import { getAuthState } from 'utils/authUtils';
import { Login } from './routes/Login';
import { NotFound } from './routes/NotFound';
import { Layout } from 'components/Layout';
import { Categories } from 'routes/Categories';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Recipe } from 'routes/Recipes/Recipe';
import { CategoriesProvider } from 'context/CategoriesContext';
import { RecipesProvider } from 'context/RecipesContext';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const authorizationState = getAuthState();

  if (!authorizationState) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

function App() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        element={
          <RecipesProvider>
            <CategoriesProvider>
              <Layout />
            </CategoriesProvider>
          </RecipesProvider>
        }
      >
        <Route
          path="/"
          element={
            <RequireAuth>
              <Recipes />
            </RequireAuth>
          }
        />
        <Route
          path="/recipes"
          element={
            <RequireAuth>
              <Recipes />
            </RequireAuth>
          }
        />
        <Route
          path="/recipes/:id"
          element={
            <RequireAuth>
              <Recipe />
            </RequireAuth>
          }
        />
        <Route
          path="/categories"
          element={
            <RequireAuth>
              <Categories />
            </RequireAuth>
          }
        />
      </Route>
      <Route path="login" element={<Login />} />
    </Routes>
  );
}

export default App;
