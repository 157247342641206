import { Recipe } from 'api/Recipe';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

interface Context {
  recipes: Recipe[];
  onRecipesChange: (value: Recipe[]) => void;
}

const RecipesContext = createContext<Context | null>(null);

export const RecipesProvider = ({ children }: { children: ReactNode }) => {
  const [recipes, setRecipes] = useState<Recipe[]>([]);

  const onRecipesChange = useCallback((value: Recipe[]) => {
    setRecipes(value);
  }, []);

  return (
    <RecipesContext.Provider value={{ recipes, onRecipesChange }}>
      {children}
    </RecipesContext.Provider>
  );
};

export const useRecipes = () => {
  const recipesContext = useContext(RecipesContext);
  if (!recipesContext) {
    throw new Error('useRecipes must be used within a RecipesProvider');
  }
  return recipesContext;
};
