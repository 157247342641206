import { Category } from './data/categories';
import { CookTime } from './data/cookTime';

import { recipes } from './data/recipes';

export interface Recipe {
  _id: string;
  name: string;
  description?: string;
  cookTime?: CookTime;
  ingredients?: string[];
  instructions?: string[];
  servings?: number;
  imageUrl?: string;
  categories?: Category[];
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Recipe = {
  getAll() {
    return recipes;
  },
  getOne(id: string) {
    return recipes.find((x) => x._id === id);
  },
};
