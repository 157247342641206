import { Recipe } from 'api/Recipe';
import { Category } from './categories';
import { CookTime } from './cookTime';

export const recipes: Recipe[] = [
  {
    _id: '629086a95ec4f7fcb10ad98e',
    name: 'Čili sriuba',
    cookTime: CookTime['1 h'],
    ingredients: [
      '500g faršo',
      '    Česnakinio batono1 čili pipiras',
      '    100g pomidorų padažo',
      '    1 skardine peeled pomidorų',
      '    1 česnako skiltelė',
      '    400g kidney pupelių',
      '    1 paprika',
      '    1 svogūnas',
      '    100g fermentinio sūrio',
      '    čili prieskonių',
      '    druskos',
    ],
    instructions: [
      'Pomidorus, čili papriką, papriką, česnaką sumalti',
      '    Faršą su svogūnu pakepti',
      '    Faršą sumest ant sumaltos masės ir šiek tiek sumalti irgi',
      '    Į puodą supilt sumaltą masę, užpilt šiek tiek vandens, pomidorų padažo.Patroškint 30min',
      '    Pakept česnakinį batoną',
    ],
    servings: 4,
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1653638824/recipes/cili-sriuba.jpg-2022-05-27T08:07:02.738Z.jpg',
    categories: [Category.Kiauliena, Category.Pietus, Category.Sriubos],
    description: 'Skani čili sriubaška',
  },
  {
    _id: '6290abb990b92ae87fc2d234',
    name: 'Dorados',
    cookTime: CookTime['2 h'],
    ingredients: [
      '2 dorados',
      '   60g sviesto',
      '   5 riekelių citrinos',
      '   4 skiltelių česnako',
      '   druskos',
      '   pipirų',
      '   petražolių',
      '   svogūnlaiškių',
      '   ',
    ],
    instructions: [
      'Doradas išvalykite ir vieną jų pusė įpjaukite tris kartus išilgai',
      '   Į žuvų pilvo ertmę sudėkite nupjautus petražolių kotus bei kuokštą svogunlaiškių',
      '   Česnakus nulupkite ir sutrinkite su druska grūstuvėje ar plokščiąja peilio dalimi',
      '   Minkštą sviestą sumaišykite su česnakais, druska ir gera sauja smulkintų petražolių',
      '   Žuvį truputį pabarstykite druska ir pipirais',
      '   Sviestą su prieskoniais įtaisykite į įpjovas, o likusį sudėkite į žuvies vidų.',
      '   Ant kiekvienos žuvies uždėkite po porą citrinos griežinėlių',
      '   Pašaukite į 180C orkaitę ir kepkite apie 50 minučių kol žuvis pakeis spalvą, o citrina lengvai susitrauks ir paruduos, bet nesudegs',
      '   Baigiant kepti sudėti virtus ryžius į ištirpusį sviestą ir pakepki dar kelias minutes',
    ],
    servings: 2,
    categories: [Category.Zuvis, Category.Pietus],
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1653651305/recipes/283388572_778868726430501_1273846031353604590_n.jpg-2022-05-27T11:35:04.395Z.jpg',
  },
  {
    _id: '6290b8bf6a7bb93c650a546e',
    name: 'Plovas',
    ingredients: [
      '500g kiaulienos sprandinės',
      ' 3 svogūnai',
      ' 2-3 morkos',
      ' 400g basmati ryžiai',
      ' Druska',
      ' Pipirai',
      ' Paprikos prieskoniai',
      ' Kalendra',
      ' Kuminas',
      ' 1 česnako galva',
    ],
    instructions: [
      'Mėsą supjaustyti nedideliais gabaliukais ir palikti su prieskoniais apie 30min',
      ' Kol marinuojasi supjaustyti morkas šiaudeliais ir svogūnus',
      ' Įdėti kepti mėsą. Kai apskrunda sudėti svogūnus ir po 5min morkas. Viską pakepinti dar 5-8min. Idėti dar papildomai prieskonių',
      ' Supilti ryžius tolygiai ir į vieną vietą po biški supilti verdantį vandenį kol apsems ryžius apie 3cm',
      ' Po 2-3 min uždėti dangtį ir palikti troškintis apie 15min',
    ],
    servings: 4,
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1653651646/recipes/075344452-e2104336-bdbd-4580-8a7f-73723fd3a57b.jpg-2022-05-27T11:40:45.936Z.jpg',
    categories: [Category.Pietus, Category.Kiauliena],
    cookTime: CookTime['1 h'],
  },
  {
    _id: '6290c1f86a7bb93c650a54c6',
    name: 'Tušinti kopūstai',
    cookTime: CookTime['30 min'],
    ingredients: [
      'Raugintų kopūstų',
      'Dešrelių rukytų',
      'Pomidorų padažo',
      'Lauro lapų',
      'Bulvių',
      'Druskos',
      'Pipirų',
    ],
    instructions: [
      'Raugintus kopūstus sudėkite į puodą, jei kopūstai per rūgštūs, nuplaukite kelis kartus po tekančiu vandeniu. Įpilkite 1 stiklinę vandens, įdėkite 1 šaukštą aliejaus, išmaišykite ir troškinkite 10 minučių.',
      'Dėkite griežinėliais supjaustytas dešreles, pomidorų padažą, druską, pipirus, įpilkite truputėlį vandens ir troškinkite dar 10 minučių. Patiekite su virtomis bulvėmis.',
    ],
    servings: 4,
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1653654006/recipes/18812.jpg-2022-05-27T12:20:05.644Z.jpg',
    categories: [Category.Pietus, Category.Kiauliena],
  },
  {
    _id: '6290c2b16a7bb93c650a54ce',
    name: 'Šviežių kopūstų sriuba',
    cookTime: CookTime['30 min'],
    ingredients: [
      'Kopūstas',
      '  Morka',
      '  3 bulves',
      '  1.5l sultinio',
      '  Pipirų',
      '  Druskos',
      '  Lauro lapų',
    ],
    instructions: [
      'Supjaustyti kopūstą, morką, bulves.',
      '  Sudėti viską į puodą su prieskoniais',
      '  Pavirti apie 25 min',
    ],
    servings: 4,
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1653654233/recipes/receptai-38-85838039.jpg-2022-05-27T12:23:53.125Z.jpg',
    categories: [Category.Sriubos],
  },
  {
    _id: '6290c3af6a7bb93c650a54db',
    name: 'Agurkinė sriuba',
    cookTime: CookTime['1 h'],
    ingredients: [
      '1.5l sultinio',
      '3 agurkai',
      '100g perlinių kruopų',
      '100g šoninės',
      '1 morka',
      '1 svogūnas',
      '2 skilt česnako',
      'Druskos',
      'Pipirų',
      'Lauro lapų',
    ],
    instructions: [
      ' Vandenyje arba sultinyje išvirti kruopas, prieš tai jas galima pamirkyti, kad greiciau išvirtų.',
      'Keptuvėje ant trupučio aliejaus arba sviesto pakepintis smulkintus svogūnus, česnaką, tarkuotas morkas ir supjaustytą šoninę.',
      'Į išvirusias kruopas sudėti pakepintas daržoves ir šoninę bei kubeliais pjaustytas bulves. Pagardinti prieskoniais ir pavirti viską apie 10 minučių.',
      'Sudėti kubeliais pjaustytus raugintus agurkus, jeigu norisi rūgštelės, galima įpilti skysčio nuo agurkų. Pavirti apie 5 minutes. Patiekiant pagal skonį galima pagardinti krapais, pertražolėmis ir grietine.',
    ],
    servings: 4,
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1653654446/recipes/IMG_0515.JPG-2022-05-27T12:27:25.582Z.jpg',
    categories: [Category.Sriubos],
  },
  {
    _id: '6290c48c6a7bb93c650a54ff',
    name: 'Kugelis',
    cookTime: CookTime['1 h'],
    ingredients: [
      '1.5kg bulvių',
      'Pipirų',
      'Svogūno',
      'Kiaulienos kumpio ar dešrelių',
      'Miltai',
      'Stiklinė pieno',
      'Druska',
    ],
    instructions: [
      'Nuskutau ir sutarkavau bulves (kiekis priklauso nuo kūgelio dydžio). ',
      'Į dubenį su bulvėmis įdėjau druskos ir pipirų pagal skonį.',
      'Keptuvėje apkepinau smulkiai supjaustytus svogūnus bei rūkytos dešros gabaliukus, visą tai sudėjau į dubenį su tarkuotomis bulvėmis ir įpyliau stiklinę šilto pieno. Pieno kiekis priklauso nuo to, kokio kūgelio norite: jei norite kietesnio - reikia pilti mažiau pieno ir įdėti daugiau miltų, jei patinka skystesnis kūgelis - įpilkite daugiau pieno ir mažiau berkite miltų, arba iš viso jų neberkite. ',
      'Aš supyliau visą stiklinę pieno ir įdėjau kokius 5-7 šaukštus miltų.',
      'Viską sumaišiau ir sudėjau į išteptą sviestu ir pabarstytą miltais formą. ',
      'Kepiau apie 180 laipsnių temperatūroje 50 min.',
      ' Tačiau laikas priklauso nuo kūgelio dydžio. Visada galima pradurti kūgelį mediniu dantų krapštuku ir įsitikinti ar kugelis yra iškepes.',
    ],
    servings: 4,
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1653654667/recipes/23677.jpg-2022-05-27T12:31:06.731Z.jpg',
    categories: [Category.Pietus, Category.Vakariene],
  },
  {
    _id: '6290c53d6a7bb93c650a5504',
    name: 'Šonkauliai',
    cookTime: CookTime['1 h'],
    ingredients: [
      '1,5 kg kiaulienos šonkaulių',
      '100 ml Klasikinio kepsnių padažo',
      '6–8 skiltelių česnako',
      '50 ml aliejaus',
      '1 šaukštelio maltos aštriosios paprikos',
      '1 šaukštelio maltų kalendrų',
      '3 šaukštų Geltonųjų saldžiųjų garstyčių',
      '3 šaukštų balzaminio acto',
      '2 šaukštų rudojo cukraus',
      '2 šaukštų druskos',
    ],
    instructions: [
      "Česnaką susmulkinkite. Sumaišykite visus prieskonius ir česnaką. Kitame inde sumaišykite klasikinį kepsnių padažą, geltonąsias saldžiąsias garstyčias, aliejų ir actą, suberkite cukrų ir druską ir palaikykite marinatą 10–15 min.'Šonkaulius sudėkite į marinatą ir palikite nakčiai marinuotis.",
      'Išimkite šonkaulius iš marinato – jį išsaugokite kepimui. Kepkite šonkaulius ant vidutiniškai įkaitusių žarijų, kartkartėmis patepdami šonkaulius marinatu.',
      'Taip pat galite iškepti šiuos šonkaulius ir orkaitėje. Sudėkite juos į skardą, supilkite marinatą, uždenkite kepimo popieriumi ir kepkite 40 min. iki 190 °C įkaitintoje orkaitėje. Tuomet atidenkite ir pradėkite laistyti šonkauliukus susidariusiu padažu. Kepkite dar 30 min.',
      'Šonkaulius patiekite su Klasikiniu kepsnių padažu.',
    ],
    servings: 4,
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1653654844/recipes/d875bb_5c3b8ad14e874ba795cdab0d85748ebb%7Emv2.jpg-2022-05-27T12:34:03.108Z.jpg',
    categories: [Category.Pietus, Category.Kiauliena],
  },
  {
    _id: '6290c5bc6a7bb93c650a5509',
    name: 'Pomidorų sriuba su pupelėmis',
    cookTime: CookTime['30 min'],
    ingredients: [
      '750 mililitrų sultinio',
      '400 gramų konservuotų pomidorų savo sultyse (smulkintų ar šviežių)',
      '250 gramų konservuotų pupelių (arba virtų)',
      '1 vienetas svogūnų',
      '1 vienetas morkų',
      '1 vienetas paprikos (nebūtina)',
      '1 saujelė žalumynų (nebūtina)',
      '2 skiltelės česnako',
      'šiek tiek aliejaus',
      'šiek tiek druskos',
      'šiek tiek pipirų',
      'šiek tiek malto muskato',
    ],
    instructions: [
      'Svogūnus, česnaką, papriką susmulkinkite. Morką sutarkuokite.',
      'Puode įkaitinkite aliejų, kelias minutes apkepinkite svogūnus ir česnakus, tuomet dėkite papriką ir morką, pakepinkite, kol morkos šiek tiek susileis 5 - 8 minutes.',
      'Į puodą supilkite konservuotus pomidorus, nukoštas pupeles, patroškinkite kelias minutes, tuomet pilkite sultinį ir berkite prieskonius. Užvirinkite ir pavirkite ant nedidelės ugnies 10 minučių.',
      'Patiekdami galite užberti smulkintų žalumynų. Skanaus!',
    ],
    servings: 4,
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1653654971/recipes/19124.jpg-2022-05-27T12:36:11.249Z.jpg',
    categories: [Category.Sriubos],
  },
  {
    _id: '6290c6936a7bb93c650a550e',
    name: 'Žirnių sriuba',
    cookTime: CookTime['1 h'],
    ingredients: [
      '2l vištienos sultinio',
      '1 stikline žirnių',
      '1 morka',
      '3 bulves',
      'Lauro lapai',
      'Krapai',
      'Šoninės',
    ],
    instructions: [
      'Užmerkti žirnius karštam vandeny arba iš vakaro',
      'Supjaustyti šoninę, morką ir svogūną bei pakepinti viską keptuvėje',
      'Supjaustyti bulves ir sudėti į puodą su sultiniu',
      'Sudėti keptą šoninę į puodą',
      'Viską pavirti apie 25min',
    ],
    servings: 4,
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1653655186/recipes/zirniu-sriuba.jpg-2022-05-27T12:39:45.983Z.jpg',
    categories: [Category.Sriubos],
  },
  {
    _id: '6290ca146a7bb93c650a551b',
    name: 'Šaltibarščiai',
    cookTime: CookTime['30 min'],
    ingredients: [
      'Kefyras',
      'Burokėlių slovikas',
      '2 agurkai',
      'Svogūnlaiškiai',
      'Krapai',
      'Bulvės',
      '1 kiaušas',
    ],
    instructions: [
      'Sumest bulves (ir jei norime - kiaušą) virti',
      ' Supjaustyt agurkus, svogūnlaiškius ir krapus',
      'Sudėt viską į puodą kartu su burokėliais',
      'Užpilt kefyro ir šiek tiek vandens pagal poreikį',
    ],
    servings: 4,
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1653656083/recipes/645992-170311-756x425.jpg-2022-05-27T12:54:42.682Z.jpg',
    categories: [Category.Sriubos, Category.Pietus, Category.Vakariene],
  },
  {
    _id: '629e3baa108bb6988c6910d1',
    name: 'Manų košė',
    cookTime: CookTime['30 min'],
    ingredients: [
      'pieno 500 mililitrų',
      'vandens šlakelio',
      'manų kruopų: 4 valgomųjų šaukštų',
      'cukraus: 1-2 valgomųjų šaukštų (pagal skonį)',
      'druskos: žiupsnelio',
      'rudo cukraus: pagal skonį',
      'cinamono: pagal skonį',
    ],
    instructions: [
      'Į puodą, kuriame gaminsite manų košę, pilkite šlakelį vandens, pilkite pieną, berkite cukrų, užvirinkite, primažinkite kaitrą ir plona srovele, vis pamaišydami, suberkite manų kruopas.',
      'Nuolatos maišydami pavirinkite maždaug 3-4 minutes, išjunkite kaitrą ir uždengus dangtį dar palikite puodą pastovėti ant viryklės apie 10 minučių.',
      'Po to manų košę sudėkite į pasirinktą lėkštę, košės paviršių apibarstykite ruduo cukrumi ir cinamonu. Skanaukite!',
    ],
    servings: 2,
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1654537129/recipes/FDF9598E-C628-40BC-B225-C4D40BA4DFF1.jpeg-2022-06-06T17:38:48.142Z.jpg',
    categories: [Category.Koses, Category.Pietus, Category.Pusryciai],
  },
  {
    _id: '62bee41dc82dba219d703ef6',
    name: 'Vištos sparneliai',
    cookTime: CookTime['1 h'],
    ingredients: [
      '0.5 kg vištienos sparnelių',
      '2 šaukštai sojos padažo',
      '1 šaukštas garstyčių',
      '1 šaukštas skysto medaus (arba ištirpinto kieto)',
      '5 skiltelės česnako',
      'šiek tiek druskos',
      'šiek tiek pipirų',
    ],
    instructions: [
      'Česnaką susmulkinkite, sumaišykite su sojos padažu, garstyčiomis ir medumi. Pagal skonį pagardinkite druska ir pipirais.',
      'Sparnelius sumaišykite su marinatu. Aš dažniausiai sparnelius sudedu į didesnį, tvirtesnį maišą, užpilu mariantu ir gerai papurtau, taip sparneliai tolygiau pasidengia. Marinuoti bent dvi valandas.',
      'Sparnelius sudėkite į kepimo formą (aš dar kartais iškloju kepimo popieriumi, kad neprikeptų). Supilkite likusį marinatą.',
      'Kepkite iki 200 C laipsnių įkaitintoje orkaitėje 30 - 35 minutes, kol gražiai apskrus.',
    ],
    servings: 2,
    imageUrl:
      'https://res.cloudinary.com/dmckzsz3u/image/upload/v1656677404/recipes/18755.jpg-2022-07-01T12:10:03.299Z.jpg',
    categories: [Category.Vistiena, Category.Pietus],
  },
  {
    _id: '62bee41dc82dba21123',
    name: 'Nachos',
    cookTime: CookTime['1 h'],
    ingredients: [
      '1 pakuotės Nacho traškučių',
      '150 g čederio sūrio',
      '1 avokado',
      '1 pomidoro',
      'Čili pipiro',
      'Šviežios kalendros',
      '1 laimo',
      '1 laimo',
      'Mėsos padažui reikės:',
      '200 g maltos jautienos',
      '1 skiltelės česnako',
      '3 v.š. konservuotų kukurūzų',
      '3 v.š. konservuotų pupelių',
      '3 v.š. Taco prieskonių mišinio',
      '3 v.š. švelnaus arba aitraus Taco padažo',
    ],
    instructions: [
      'Ruošiame mėsos padažą: keptuvėje įkaitiname aliejų, dedame maltą jautieną ir iškepame. Įtarkuojame česnaką, dedame kukurūzus, pupeles, Tako prieskonius ir pakepiname maišydami minutę. Tuomet sudedame Taco padažą ir išmaišome.',
      'Avokadą sutriname su laimo sultimis, druska ir pipirais.',
      'Pasiruošiame visus priedus: sūrį sutarkuojame, daržoves supjaustome.',
      'Į kepimo formą beriame traškučius, ant jų kiek įmanoma tolygiai paskirstome mėsos padažą. Ant viršaus beriame tarkuotą sūrį. Kepimo formą dedame į 150 laipsnių orkaitę ir kepame 10 minučių.',
      'Ant viršaus tolygiai užberiame kitus priedus: pomidoro kubelius, trintą avokadą, čili griežinėlius, kalendrą. Viską apšlakstome aitriųjų paprikų padažu ir laimo sultimis.',
      'Šalia galima patiekti sūrio skonio arba kitą mirkomąjį padažą “nuogiems” traškučiams pamirkyti.',
    ],
    servings: 4,
    imageUrl:
      'https://i2.wp.com/www.downshiftology.com/wp-content/uploads/2022/03/Nachos-main-1.jpg',
    categories: [Category.Vakariene, Category.UzkandziaiVakareliams],
  },
  {
    _id: 'a62bfdfddee41dc82dba21123',
    name: 'Raudonųjų lęšių troškinys',
    cookTime: CookTime['1 h'],
    ingredients: [
      '1 v.š. kokosų aliejaus',
      '1 svogūnas, smulkiai supjaustytas',
      '4 morkos, supjaustytos kubeliais',
      '1-2 a.š. kario miltelių',
      '2 česnako skiltelės, smulkiai sukapotos',
      '1,5 stikl. raudonųjų lęšių, gerai nuplauti',
      '5 stikl. daržovių sultinio arba vandens',
      '2 lauro lapai',
      '4 bulvės',
      '3-4 lapinio kopūsto lapai, nuimti nuo koto ir smulkiai supjaustyti (arba panašus kiekis špinatų)',
      '1-2 žaliosios citrinos (galima keisti paprasta citrina)',
      'kalendros arba petražolių lapeliai (patiekimui)',
      'druska ir šviežiai malti juodieji pipirai',
    ],
    instructions: [
      'Puode, kuriame virsime troškinį, įkaitiname aliejų ir suberiame smulkintus svogūna, morkas ir kario miltelius. Ant nedidelės kaitros vis pamaišant kepame apie 5-7 minutes, kol svogūnas taps skaidrus.',
      'Suberiame smulkintą česnaką ir pakepame dar vieną minutę.',
      'Sudedame lęšius, įpilame karštą daržovių sultinį arba vandenį, įmetame lauro lapelius, jei reikia, šiek tiek pasūdome (kario milteliai ir sultinys savyje gali turėti druskos, tad ragaujame ar tikrai reikia papildomos druskos) ir verdame apie 10 minučių, kol lęšiai šiek tiek sukrenta (jei lęšiai neskaldyti, juos reikės pavirti kiek ilgiau).',
      'Suberiame bulves ir toliau verdame dar apie 10 minučių (priklausomai nuo bulvių gabaliukų dydžio), kol bulvės suminkštėja.',
      'Pačiame gale suberiame smulkintus lapinio kopūsto lapus ir paverdame 1 minutę. Jei naudosite špinatus, virti jų nebūtina, ir taip sukris nuo karščio.',
      'Patraukiame puodą nuo kaitros, įspaudžiame vienos žaliosios citrinos (arba pusę paprastos citrinos) sulčių, jei reikia, įberiame druskos ir paskaniname juodaisiais pipirais.',
      'Įpilus į lėkštes, užberiame smulkintais kalendros arba petražolių lapeliais. Šalia galima patiekti ketvirčiais pjaustytą žaliąją ar paprastą citriną – norintiems papildomai pasiskaninti.',
    ],
    servings: 4,
    imageUrl:
      'https://www.valgykimesveikiau.lt/wp-content/uploads/2019/10/Greitas-lesiu-troskinys_su-saukstu-1536x1152.jpg',
    categories: [Category.Pietus, Category.Vakariene, Category.Vegetariska],
  },
  {
    _id: 'a62bddgggfddee41dc82dba21123',
    name: 'Varškės blyneliai',
    cookTime: CookTime['30 min'],
    ingredients: [
      'varškės 3-4 pak. (po 180g)',
      '1 bananas',
      '2-3 kiaušiniai',
      '3-4 šaukštai miltų',
      '1 šaukštas aguonų',
      '1 sauja razinų (jei nori)',
    ],
    instructions: [
      'Razinas sudedu į sietelį ir perlieju verdančiu vandeniu, jog nuplaučiau nešvarumus ir aliejų, kuriuo jos dažnai padengiamos. Jeigu razinos labai sausos, galima užpilti verdančiu vandeniu ir palikti pastovėti 2-3 minutes, o tada nusunkti. Jeigu naudojate minkštas datules, nieko daryti nereikia.',
      'Į dubenį dedu varškę, razinas, kiaušinius, citrinos žievelę, vanilės esenciją ir rankiniu elektriniu trintuvu (vadinamu blenderiu) viską sutrinu iki glotnios masės, kad nesijaustų varškės grūdelių. Dedu miltus, gerai išmaišau. Masė turi būti šaukštu maišoma, bet labai tiršta - tirštesnė už riebią grietinę, tokia sunkokiai krentanti nuo šaukšto. Jeigu būtų skystesnė, įdėkite dar šiek tiek miltų.',
      'Bananą sutrinu šakute bet ne iki tyrelės - jog liktų bananų gabaliukų. Įmaišau į tešlą drauge su aguonomis.',
      'Keptuvėje įkaitinu šiek tiek lydyto sviesto. Jo kiekį žiūrėkite pagal keptuvę - maniškė storadugė, netraukianti, tad sviesto pakanka simboliškai.',
      'Kabinu po dosnų šaukštą tešlos ir dedu į keptuvę šaukštu, truputį paplodama ir suformuodama apvalius blynelius. Ugnį sumažinu iki vidutinės, gal net šiek tiek mažesnės (blynukai stori, jiems geriau kepti lėtai, kad nesudegtų). Kai matau, kad blynelių apačia sutvirtėjo tiek, kad galėčiau sėkmingai apversti, verčiu ir gražiai apkepu kitą pusę.',
    ],
    servings: 2,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/without-watermark/24450.jpg',
    categories: [Category.Pusryciai, Category.Vakariene],
  },
  {
    _id: 'a62bhgkjhkhfddee41dc82dba21123',
    name: 'Avižinė košė',
    cookTime: CookTime['30 min'],
    ingredients: [
      'puse stiklinės avižų',
      'stiklinė vandens',
      '1 bananas',
      'šiek tiek smulkintų, pjaustytų migdolų',
      'cinamono',
    ],
    instructions: ['Išvirti avižas kelias min.', 'Palaikyti uždengtas 3min.', 'Sudėti pagardus.'],
    servings: 1,
    imageUrl: 'https://www.ndtv.com/cooks/images/Almond-and-Banana-Oats.jpg',
    categories: [Category.Pusryciai, Category.Koses],
  },
  {
    _id: 'a62bhgkjhkhfdde35kje41dc82dba21123',
    name: 'Trintų moliūgų sriuba',
    cookTime: CookTime['1 h'],
    ingredients: [
      '1 nedidelio moliūgo',
      '1 morkos',
      '6 česnako skiltelių',
      'druskos, pipirų',
      '1 l. vištienos sultinio',
      '100 ml. grietinėlės',
      'Alyvuogių aliejaus',
    ],
    instructions: [
      'Į kepimo skardą dedame dideliais gabalais pjaustytą moliūgą, morką, česnako skilteles. Sumaišome daržoves su šlakeliu alyvuogių aliejaus, druska, pipirais, čiobreliu. Kepame 190 laipsnių orkaitėje apie 40 min.',
      'Užverdame sultinį, dedame virti bulvę.',
      'Iškepusias daržoves sudedame į sriubą ir paverdame dar 5 min.',
      'Sutriname blenderiu ir paragaujame, jei norisi – dar skaniname prieskoniais.',
      'Pilame grietinėlę, išmaišome.',
      'Patiekiame su skrudintomis moliūgo sėklomis, tyru alyvuogių aliejumi, balzamiko kremu ir keliais čili griežinėliais.',
    ],
    servings: 4,
    imageUrl:
      'https://i0.wp.com/antmedineslenteles.com/wp-content/uploads/2016/10/keptu-moliugu-sriuba1.jpg?fit=720%2C720&ssl=1',
    categories: [Category.Sriubos, Category.Pietus, Category.Vakariene],
  },
  {
    _id: 'a62bhgkjhkhfddee41dcll382dba21123',
    name: 'Makaronai su smidrais ir šonine',
    cookTime: CookTime['30 min'],
    ingredients: [
      '200 g makaronų',
      '200 g šaltai rūkytos šoninės',
      '200 g smidrų',
      '120 ml balto vyno (arba sultinio)',
      '120 g	kietojo sūrio (tarkuoto)',
      '1 žiupsnelis 	druskos',
      '1 pundelis 	baziliko',
    ],
    instructions: [
      'Į puodą pilti vandens, įberti druskos ir užvirti. Suberti makaronus ir virti apie 10 minučių iki kol išvirs.',
      'Šoninę supjaustyti mažesniais gabalėliais, sudėti į keptuvę. Kepti ant vidutinės kaitros nuolat pamaišant iki kol apskrus ir taps traški. Tuomet šoninę išimti ir sudėti ant popierinio rankšluosčio, kad susigertų riebalai.',
      'Smidrų galus nupjauti, o smidrus supjaustyti mažesniais gabalėliais.',
      ' Į tą pačią ir nevalytą keptuvę sudėti smidrus ir pakepti apie 5–6 minutes, retkarčiais pamaišydami, kol iškeps.',
      'Tuomet smidrus išimti kiaurasamčiu ir atidėti.',
      'Į keptuvę pilti vyną ir maišant pavirti apie 5 minutes.',
      'Kai makaronai išvirs, išgriebti ir sudėti į keptuvę, taip pat sudėti smidrus, šoninę, baziliką ir apie 60 gramų sūrio, išmaišyti.',
      'Paruoštą patiekalą sudėti į lėkštes, pabarstyti sūriu ir skanauti.',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/thumb920x573/39501.jpg',
    categories: [Category.Pietus, Category.Vakariene],
  },
  {
    _id: 'a62bhgkjhkhfddhghjkee41dc82dba21123',
    name: 'Orkaitėje kepti kotletai',
    cookTime: CookTime['1 h'],
    ingredients: [
      '1 kg faršo',
      '125 ml vandens',
      '2 kiaušiniai',
      '3 riekelės batono',
      '1 svogūnas',
      '2 šaukštai grietinės',
      'krapų, druskos, juodūjų pipirų, česnako granulių',
    ],
    instructions: [
      'Į faršą įmušti kiaušinius, pagardinti druska, juodaisiais pipirais ir česnako granulėmis.',
      'Batono riekeles užpilti šaltu vandeniu arba pienu, po 10 minučių nuspausti ir sudėti į faršą.',
      'Svogūną sutarkuoti smulkiausia tarka, krapus smulkiai sukapoti, viską sudėti kartu su grietine į faršą, ir gerai išmaišyti. Paruoštą faršą "išmušti", paimti dalį faršo, kokio dydžio bus ir kotletas, ir tą dalį kelis kartu mesti ant pjaustymo lentos. Tai leidžia lengvai suformuoti kotletą, kuris kepant nesubyrės.',
      'Paruoštus kotletus sudėti į kepimo indą ir kepti 180 C laipsnių orkaitėje apie 20 minučių. Tuomet į kepimo indą įpilti apie pusę puodelio vandens ir kepti dar 15 minučių.',
    ],
    servings: 10,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/without-watermark/20136.jpg',
    categories: [Category.Pietus, Category.Kiauliena],
  },
  {
    _id: 'a62bhgkjhkhfddegghe41dc82dba21123',
    name: 'Klasikiniai buritai',
    cookTime: CookTime['30 min'],
    ingredients: [
      '100 g ryžių',
      '400 g maltos jautienos',
      '100 g konservuotų juodųjų pupelių',
      '1 pakuotės Taco prieskonių',
      '2 skiltelių česnako',
      '3 v.š. Taco padažo',
      '150g čederio',
      '1/4 iceberg salotos',
      '2 pomidorų',
      '8 vidutinių arba 6 didelių tortilijų',
    ],
    instructions: [
      'Išverdame ryžius ir leidžiame jiems pravėsti, kol ruošime kitus priedus. ',
      'Ruošiame mėsos įdarą: keptuvėje įkaitiname aliejų, dedame maltą jautieną ir maišydami kepiname apie 7 minutes, kol mėsa iškepa. Įtarkuojame česnaką ir kepiname dar minutę. Suberiame pupeles bei prieskonių mišinį, išmaišome ir kepiname dar minutę. Dedame padažą ir išmaišome. Sūrį sutarkuojame, salotą supjaustome juostelėmis, pomidorus – kubeliais.',
      'Tortilijos centre dedame ryžių, mėsos įdaro, sūrio, daržovių ir jei norisi, apšlakstome jalapenų pagardu.',
      'Sukame: įdarą tvirtai apgaubiame apatine tortilijos dalimi, tuomet užlenkiame šonus ir susukame į tvirtą suktinuką. Kiekvieną buritą papildomai tvirtai susukame į foliją.',
      'Šiame žingsnyje galite valgyti buritus tokius, kokie yra arba pašildyti 180 laipsnių orkaitėje 15 min ir valgyti karštus. Likusius buritus perkelkite į šaldytuvą arba šaldiklį vėlesniam naudojimui. ',
    ],
    servings: 1,
    imageUrl:
      'https://i0.wp.com/antmedineslenteles.com/wp-content/uploads/2022/04/blog-img_3268-2.jpg?fit=720%2C720&ssl=1',
    categories: [Category.Pietus, Category.Vakariene],
  },
  {
    _id: 'a62bhgkjhkhfddee41aadc82dba21123',
    name: 'Braškių ir bananų smūtis',
    cookTime: CookTime['30 min'],
    ingredients: [
      '21 braškė',
      '1.5 obuolio',
      '2 bananai',
      '31 balta vynuogė',
      '1/4 spausto apelsino sultys',
      '17 juodujų serbentų',
    ],
    instructions: ['Viską sumesti į mikserį ir išplakti.'],
    servings: 1,
    imageUrl:
      'https://www.liveeatlearn.com/wp-content/uploads/2019/07/strawberry-banana-smoothie-vert1200.jpg',
    categories: [Category.Pusryciai, Category.Uzkandziai],
  },
  {
    _id: 'a62bhgkjhkhfd9dee41aadc82dba21123',
    name: 'Graikiškas jogurtas su granola',
    cookTime: CookTime['15 min'],
    ingredients: ['Pilno riebumo graikiškas jogurtas', 'granola', 'norimi vaisiai ar uogos'],
    instructions: ['Patiekti graikišką jogurtą su granola, vaisiais ar uogom.'],
    servings: 1,
    imageUrl: 'https://freshapron.com/wp-content/uploads/2022/05/Yogurt-Granola-Bowl-02.jpg',
    categories: [Category.Pusryciai, Category.Uzkandziai],
  },
  {
    _id: 'a62bhgkjhkhfddee41aadc82db1a21123',
    name: 'Rikotos sūris ant skrebučio',
    cookTime: CookTime['15 min'],
    ingredients: [
      'Rikotos sūrio (arba kito varškės sūrio)',
      'batono',
      'bananas',
      'kriaušė',
      'avokadas',
      'pakepti pomidoriukai',
      'mėlynės ar šilauogės',
      'kiaušiniai',
    ],
    instructions: ['Užtepti rikotos sūri ant skrebučio ir patiekti su norimais pagardais.'],
    servings: 1,
    imageUrl: 'https://thecrookedcarrot.com/wp-content/uploads/2020/02/ricotta-toast-3-1.jpg',
    categories: [Category.Pusryciai, Category.Uzkandziai],
  },
  {
    _id: 'a612bhgkjhkhfddee482dba21123',
    name: 'Sumuštinis su lašiša',
    cookTime: CookTime['15 min'],
    ingredients: ['Lašiša', 'Pilnagrūdė duonelė', 'užtepėlė arba avokadas'],
    instructions: ['Užtepti užtepėlės ant duonelės ir uždėti lašišos.'],
    servings: 1,
    imageUrl:
      'https://hips.hearstapps.com/hmg-prod/images/close-up-from-fresh-salmon-sandwich-serving-from-royalty-free-image-1674444938.jpg?crop=0.668xw:1.00xh;0.214xw,0&resize=980:*',
    categories: [Category.Pusryciai, Category.Uzkandziai],
  },
  {
    _id: 'a62b11hgkjhkhadc82dba2112w3',
    name: 'Tuno salotos',
    cookTime: CookTime['15 min'],
    ingredients: [
      'konservuoto tuno savo sultyse: 2 indelių',
      'kininių kopūstų: 0,5',
      'vyšninių pomidoriukų: 12',
      'žaliųjų alyvuogių: 12',
      'kiaušinių: 4',
      'alyvuogių aliejaus: 2 šaukštų',
      'sojos padažo: 2 šaukštelių',
      'česnako: 1 skiltelės',
      'citrinos sulčių: 1 šaukšto',
      'čiobrelių: žiupsnelio',
      'bazilikų: žiupsnelio',
    ],
    instructions: [
      'Iš pradžių kiaušinius kietai išverdame, atvėsiname, nulupame, ganėtinai stambiai supjaustome.',
      'Nuplauname kininį kopūstą, jį ganėtinai smulkiai supjaustome.',
      'Vyšnininius pomidorus perpjauname pusiau.',
      'Alyvuoges perpjauname pusiau (arba į daugiau dalių, kaip labiau norisi).',
      'Tuną nusunkiame.',
      'Užpilui išspaudžiame česnaką.',
      'Sumaišome su alyvuogių aliejumi, sojos padažu, citrinos sultimis, čiobreliais ir bazilikais.',
      'Galiausiai užpilu apšlakstome sumaišytas salotas. Skanaujame!',
    ],
    servings: 4,
    imageUrl:
      'https://worldrecipes.eu/storage/4872/conversions/tuno-salotos-per-15-minuciu-sveikas-receptas-medium.webp',
    categories: [
      Category.Pusryciai,
      Category.Uzkandziai,
      Category.Vakariene,
      Category.Zuvis,
      Category.Vegetariska,
    ],
  },
  {
    _id: 'a612bhg1kjhkhfddee482dba21123',
    name: 'Trinta grybų sriuba',
    cookTime: CookTime['1 h'],
    ingredients: [
      '250g pievagrybių',
      '800ml sultinio',
      '3vnt bulvių',
      '1 morka',
      '1 svogūnas',
      '100ml grietinėlės',
      '3 skilt česnako',
      '2 šaukštai aliejaus',
      '0.5 šaukštelio čiobrelių',
      'druskos',
      'pipirų',
      'krapų',
    ],
    instructions: [
      'Smulkiai supjaustome svogūną, česnaką. Morką sutarkuojame. Grybus supjaustome atskirai nedideliais gabalėliais. Bulves atskirai supjaustome mažais kubeliais.',
      'Keptuvėje su aliejumi pakepiname svogūnus, morką, česnakus. Kai daržovės suminkštės, išimti iš keptuvės ir atidėti.',
      'Į tą pačią keptuvę sudedame smulkintus grybus ir kepiname apie 5-7 min, vis pamaišant, kad gražiai apskrustų ir suminkštėtų.',
      ' Į puodą pilame sultinį ir jį užverdame. Dedame pjaustytas bulves ir sumažinus kaitrą, verdame 10 min. Sudedame kepintas daržoves, grybus, beriame čiobrelius, druską ir pipirus. Verdame dar kelias minutes. Supilame grietinėlę ir pakaitinus kelias minutes, išjungiame viryklę. Sutriname.',
      'Tiekiant, užberiame smulkintų krapų.',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/without-watermark/38756.jpg',
    categories: [Category.Sriubos],
  },
  {
    _id: 'a6nh12bhgkjhkhfddgee482dba21123',
    name: 'Trinta morkų ir kalafiorų sriuba',
    cookTime: CookTime['1 h'],
    ingredients: [
      '800ml daržovių sultinio (arba vištienos ar kito mėgstamo) ',
      '550g žiedinių kopūstų',
      '2vnt morkų',
      '240ml grietinėlės',
      '120g čederio sūrio tarkuoto',
      '1 svogūno',
      '3 skilt česnako',
      '1 šaukštas sviesto',
      '1 šaukštelis kario prieskonių',
      'paprikos, česnako miltelių',
      'malto muskato',
      'druskos, pipirų, žalumynų',
    ],
    instructions: [
      'Žiedinį kopūstą susmulkinti žiedynais, morką supjaustyti stambesniais griežinėliais.',
      'Kepimo formą ištiesti kepimo popieriumi, sudėti žiedinį kopūstą, morkas, pabarstyti kario prieskoniais, druska, česnako milteliais, pipirais ir aitriosios paprikos milteliais.',
      'Kepti įkaitintoje iki 200 C laipsnių temperatūros orkaitėje apie 30 minučių iki kol daržovės suminkštės. Tuomet išimti iš orkaitės ir šiek tiek atvėsinti. Kelis gabaliukus kalafiorų atsidėti tiekimui, bet jei nenorite, tikrai nebūtina.',
      'Į puodą dėti sviestą, ištirpinti.',
      'Svogūną ir česnaką susmulkinti. Svogūną suberti į puodą ir pakepti pamaišant apie 5 minutes, tuomet suberti česnaką ir pakepti dar apie 30 sekundžių.',
      'Į puodą supilti sultinį, sudėti žiedinius kopūstus su morkomis, užvirti, tuomet sumažinti kaitrą, supilti grietinėlę ir pavirti apie 15 - 20 minučių. Tuomet sutrinti elektriniu trintuvu iki vientisos masės.',
      'Į puodą su sriuba suberti sūrį, muskatą ir dar pakaitinti ant silpnos ugnies iki kol sūris išsilydys.',
      'Į dubenėlius pilti sriubos, dėti apkeptų žiedinių kopūstų, pabarstyti žalumynais ir tiekti.',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/thumb920x573/37850.jpg',
    categories: [Category.Sriubos],
  },
  {
    _id: 'a612bhgkjhkhfddee482dbfa21123',
    name: 'Suktinukai su lęšiais',
    cookTime: CookTime['1 h'],
    ingredients: [
      '2.5 puodelio žalių lęšių',
      '1 šaukštas alyvuogių aliejaus',
      '1 mažas svogūnas',
      '2 skilt česnako',
      '3 šaukštelio pomidorų pastos',
      '1 šaukštelis kumino',
      'puse šaukštelio maltos kalendros',
      '8 dideles tortilijos',
      'avokado',
      'smulkintos salotos',
      'kelių mažų pomidoriukų',
    ],
    instructions: [
      'Pasigaminkite lęšių įdarą: didelėje keptuvėje ant vidutinės ugnies įkaitinkite aliejų. Įdėkite svogūną ir kepkite, kol suminkštės, 6 minutes. Įpilkite česnako ir kepkite, kol pasidarys kvapnus, dar 1 minutę. Įpilkite pomidorų pastos ir virkite, kol pomidorų pasta šiek tiek patamsės, 2 minutes. Suberkite kuminą ir kalendras, pagardinkite druska. Įpilkite lęšių ir ¼ puodelio šalto vandens. Maišykite ir virkite, retkarčiais pamaišydami ir sutrindami, kol lęšiai įkais ir iš dalies susitrins, o skysčio nebeliks, maždaug 5 minutes. Įpilkite po šaukštą vandens, jei keptuvė išdžius.',
      'Gaminkite tortilijas: į kiekvienos tortilijos vidurį įdėkite po kaušelį prieskoniais pagardintų lęšių, palikdami laisvą kraštelį sulankstymui. Dėkite gvakamolę ir ant viršaus išbarstykite salotas ir pomidorus.',
      'Tvirtai sulenkite tortilijos kraštus link centro, sukurdami raukšles. Atsargiai apverskite tortilijas, kad klostės būtų apačioje ir liktų kartu.',
      'Keptuvėje ant vidutinės ugnies įkaitinkite nedidelį kiekį aliejaus. Kai aliejus įkaista, įdėkite po vieną tortiliją ir kepkite iki auksinės spalvos, po 3 minutes iš kiekvienos pusės. Perkelkite į lėkštę ir pakartokite su likusiomis tortilijomis.',
    ],
    servings: 8,
    imageUrl:
      'https://hips.hearstapps.com/hmg-prod/images/delish-vegan-crunchwraps-horizontal-1629757201.jpg?crop=1.00xw:1.00xh;0,0&resize=1200:*',
    categories: [Category.Pietus, Category.Vakariene, Category.Vegetariska],
  },
  {
    _id: 'a612bhf2gkjhkhfddee482dba2112gg3',
    name: 'Daržovių troškinys',
    cookTime: CookTime['1 h'],
    ingredients: [
      '1 morka',
      '2 svogūnai',
      '10 vnt pievagrybių',
      '1 baklažanas',
      '3 skilt česnako',
      '2 nedidelės cukinijos',
      '1 saldžioji paprika',
      '0.5 salierų gumbo',
      '3 pomidorai',
      '600ml daržovių sultinio',
      'druska, pipirai, čiobreliai, rozmarinai',
    ],
    instructions: [
      'Morkas smulkiai sutarkuokite, svogūną smulkiai supjaustykite, pievagrybius perpjaukite pusiau.',
      'Į įkaitintą sviestą sudėkite svogūnus, maišant kepkite apie minutę. Suberkite morkas, kepkite dar porą minučių. Suberkite pievagrybius ir kepkite vis pamaišant dar 5 minutes. Suberkite į troškinimo puodą.',
      'Baklažaną supjaustykite ir apkepkite svieste su išspaustu česnaku apie 2-3 minutes. Suberkite į puodą.',
      'Cukiniją, pomidorus ir papriką supjaustykite nedideliais gabaliukais ir suberkite į puodą. Saliero gumbą smulkiai supjaustykite ir suberkite į puodą.',
      'Užpilkite daržovių sultiniu (turi tik šiek tiek apsemti daržoves), įberkite druskos, pipirų, čiobrelių, rozmarino ir virkite ant mažos ugnies apie 30 minučių.',
    ],
    servings: 1,
    imageUrl:
      'https://g1.dcdn.lt/images/pix/1200x470/GS41tJDIEPw/nuostabusis-darzoviu-troskinys-70824832.jpg',
    categories: [Category.Pietus, Category.Vakariene, Category.Vegetariska],
  },
  {
    _id: 'a612bhgkjh9g6khfddee482dba21123',
    name: 'Tacos',
    cookTime: CookTime['1 h'],
    ingredients: [
      '8 taco tortilijos',
      '400g faršo',
      '200g konservuotų pupelių',
      '100g sūrio',
      'konservuotų kukurūzų',
      '2 pomidorų',
      'paprikų',
      'prieskonių',
    ],
    instructions: ['Pakepti faršą.', 'Pašildyti taco tortilijas.', 'Sudėti ingredientus.'],
    servings: 2,
    imageUrl:
      'https://i0.wp.com/antmedineslenteles.com/wp-content/uploads/2020/10/blog-img_2836.jpg?resize=720%2C720&ssl=1',
    categories: [Category.Pietus, Category.Vakariene],
  },
  {
    _id: 'a612bj98thgkjhkhfddee482dba21123',
    name: 'Salotos su vištiena ir mangais',
    cookTime: CookTime['1 h'],
    ingredients: [
      '250g vištienos šlaunelių arba krutinėles',
      '100g salotų',
      '1 avokado',
      '1 mango',
      '1 raudono svogūno',
      '80g fetos sūrio',
      'kario prieskonių',
      'graikinių ar kedro riešutų ar moliūgų sėklų',
      'Padažui:',
      '3 v.š. alyvuogių aliejaus',
      '1 v.š. medaus',
      '1 v.š. grūdėtųjų garstyčių',
      '1 v.š. citrinos sulčių',
    ],
    instructions: [
      'Vištieną sumaišome su alyvuogių aliejumi ir prieskoniais.',
      'Vištieną ištepti prieskoniais ir dėti į 190C orkaitę. Pakepti uždengus foliją apie 30min. Tada atidengti, įjungti apipūtimo funkciją ir dar 10-15min leisti apskrusti.',
      'Ruošiame padažą: indelyje sumaišome visus padažo ingredientus.',
      'Į lėkštes dedame nuplautas ir būtinai nusausintas salotas, avokado, mango, vištienos ir svogūnų juosteles.',
      'Užtrupiname fetą ir pabarstome kedro riešutais, druska ir pipirais.',
      'Apšlakstome paruoštu padažu.',
    ],
    servings: 2,
    imageUrl:
      'https://i0.wp.com/antmedineslenteles.com/wp-content/uploads/2023/02/blog-IMG_1976-2.jpg?resize=1024%2C1024&ssl=1',
    categories: [Category.Pietus, Category.Vakariene, Category.Vistiena],
  },
  {
    _id: 'a6a272bhgkjhkhfd9dee41aadc82dba21123',
    name: 'Makaronai su feta ir pomidorais',
    cookTime: CookTime['1 h'],
    ingredients: [
      '500g vyšninių pomidorų',
      '200g fetos sūrio',
      '400g makaronų (naudojome kriaukles)',
      '50g špinatų',
      '3skilt česnako',
      '1 šaukštelis raudonėlio arba čiobrelio',
      '1 saujelė mėtų arba baziliko',
      'maltų pipirų',
      'šiek tiek riešutų (graikinių)',
    ],
    instructions: [
      'Į kepimui tinkamą indą (naudojome 20x30 cm) dedame fetos sūrį. Aplink jį išdėliojame pomidoriukus. Viską apibarstome džiovintu raudonėliu ir užberiame smulkiai sukapotas česnako skilteles. Apšlakstome alyvuogių aliejumi ir rankomis pomidoriukus pavartome, jog tolygiau pasidentų. Dedame kepti į iki 210 laipsnių įkaitintą orkaitę 30-35 minutėms (iki pomidoriukai lengvai paskrus ir odelės ims sproginėti).',
      'Likus daugmaž 10-15 minučių iki kepimo pabaigos išverdame makaronus - sudedame į verdantį pasūdytą vandenį ir verdame pagal instrukciją ant pakuotės (mes virėme 7min). Makaronus nukošiame, o daugmaž stiklinę virimo skysčio atsidedame - jo gali reikėti padažui.',
      'Pomidoriukus ir fetą traukiame iš orkaitės. Šakute arba šaukštu sūrį patraiškome ir išmaišome, jog gautume padažą.',
      'Į padažą beriame špinatus, makaronus ir pasmulkintus mėtų lapelius (keletą atsidedame papuošimui), pabarstome juodaisiais pipirais. Išmaišome, jog visi makaronai pasidengtų padažu. Jei atrodo per sausa ir padažo norisi daugiau, įpilame truputį makaronų virimo skysčio.',
      'Patiekdami pabarstome pasmulkintais skrudintais riešutais, papuošiame atsidėtais mėtų lapeliais.',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/without-watermark/33445.jpg',
    categories: [Category.Pietus, Category.Vakariene, Category.Vegetariska],
  },

  {
    _id: 'sdfsdgfd51dfg16d1g5df5g',
    name: 'Kesadilijos su plėšyta vištiena',
    cookTime: CookTime['1 h'],
    ingredients: [
      '2 vištienos krūtinėlės filė gabalėlių',
      '1 v.š. alyvuogių aliejaus',
      '2 v.š. Chili & Lime prieskonių mišinio',
      '2 v.š. salsos padažo',
      '2 v.š. kreminio sūrio',
      '100 g čederio sūrio',
      '6 tortilijų',
      'Gvakamolei:',
      '2 gerai prinokusių avokadų',
      '1 v.š. graikiško jogurto',
      '1 laimo sulčių',
      '1 mažos skiltelės česnako',
      'Druskos, pipirų',
      '1 pomidoro',
      'Saujelės šviežios kalendros',
    ],
    instructions: [
      'Vištieną sumaišome su aliejumi ir prieskonių mišiniu.',
      'Kepame vištieną: Į keptuvę dedame vištieną, apskrudiname ant didelės kaitros po 3 minutes iš abiejų pusių, tuomet sumažiname kaitrą iki minimalios, uždengiame keptuvę ir kepame dar 15 minučių. Iškepusiai vištienai leidžiame pastovėti kambario temperatūroje 5 minutes, tuomet suplėšome plonomis juostelėmis dviejų šakučių pagalba.',
      'Plėšytą vištieną sumaišome su salsos padažu ir kreminiu sūriu.',
      'Sutarkuojame čederio sūrį.',
      'Ant tortilijos dedame vištienos įdaro, beriame sūrio ir uždengiame kita tortilija.1',
      'Keptuvėje įkaitiname šlakelį aliejaus ir dedame tortiliją kepti. Kepame ant vidutinės ugnies 3-4 minutes, kartais paspausdami tortiliją rankomis ar mentele, kad tolygiai apskrustų. Atsargiai apverčiame tortiliją ir kepame dar 3-4 minutes. Iškepusią supjaustome į 6 dalis ir taip kartojame dar du kartus.',
      'Ruošiame gvakamolę: į dubenį dedame avokadus, graikišką jogurtą, įspaudžiame laimo sultis, įtarkuojame česnaką, beriame druskos ir pipirų, sutriname šakute iki vientisos masės. Tuomet įmaišome pomidoro kubelius ir smulkintą kalendrą.',
      'Kesadilijas patiekiame su gvakamole, laimu, čili griežinėliais ir šviežia kalendra.',
    ],
    servings: 4,
    imageUrl:
      'https://i0.wp.com/antmedineslenteles.com/wp-content/uploads/2022/07/blog-img_4998-2.jpg?fit=720%2C720&ssl=1',
    categories: [Category.Pietus, Category.Vakariene, Category.Vistiena],
  },
  {
    _id: '1sdfsdgfd51dfg16d1g5df5g',
    name: 'Keptų moliūgų ir burokėlių salotos su feta',
    cookTime: CookTime['1 h'],
    ingredients: [
      '500g moliūgų',
      '500g burokėlių',
      '200g fetos sūrio',
      '100g rukolos salotų',
      '80g graikinių riešutų',
      '6 v.š. alyvuogių aliejaus',
      'prieskoninių žolelių',
    ],
    instructions: [
      'Moliūgą supjaustykite stambiais gabalais ir iškepkite orkaitėje, suvynioję į foliją. Svarbu neperkepti, kad neištižtų.',
      'Burokėlius nulupkite, supjaustykite kubeliais ir taip pat iškepkite orkaitėje, suvynioję į foliją.',
      'Iškepusius moliūgus supjaustykite kubeliais ir apkepinkite keptuvėje su nedideliu kiekiu aliejaus, kad gražiai apskrustų.',
      'Burokėlius sumaišykite su moliūgais bei rukola ir suberkite į dubenį. Apibarstykite paskrudintais graikiniais riešutais bei susmulkintu fetos sūriu, pašlakstykite aliejumi, pagardinkite žolelėmis ir patiekite.',
    ],
    servings: 2,
    imageUrl:
      'https://g1.dcdn.lt/images/pix/1200x470/7qbTcyIsThY/keptu-moliugu-ir-burokeliu-salotos-su-feta-66945846.jpg',
    categories: [Category.Pietus, Category.Vakariene, Category.Vegetariska],
  },
  {
    _id: 'sdfsdgf2d51dfg16d1g5df5g',
    name: 'Vištiena kokoso pieno padaže',
    cookTime: CookTime['1 h'],
    ingredients: [
      '800g vištienos krūtinėlės',
      '400ml kokosų pieno',
      '1 raudonosios paprikos',
      '1 svogūno',
      'saujelė kalendros',
      '3 skilt česnako',
      '2 v.š. alyvuogių aliejaus',
      '2/3 šaukštelio maltos ciberžolės',
      '0.5 šaukštelio paprikos milterlių',
      '0.5 šaukštelio malto kumino',
      '0.5 šaukštelio maltos kalendros',
      '1/3 šaukštelio maltų juodųjų pipirų',
      'druskos',
    ],
    instructions: [
      'Vištienos krūtinėles supjaustome nedideliais, pailgais gabalėliais, apibarstome druska, pipirais ir įtriname.',
      ' Didelėje keptuvėje ant vidutinės ugnies įkaitiname 1 valg. š. alyvuogių aliejaus. Sudedame vištienos gabalėlius, kepame ~3-4 min, tada apverčiame visus gabalėlius ir kepame dar ~3-4 min. Taip kepant vištiena išlaikys sultingumą. Kai vištiena iškepa – išimame iš keptuvės ir laikome šiltai.',
      'Svogūną nulupame, supjaustome mažais kubeliais. Iš jalapeno ir raudonos paprikos išimame sėklytes. Jalapeno pipirus supjaustome labai smulkiai, o raudoną papriką supjaustome nedidelėmis juostelėmis. Česnaką nulupame, susmulkiname.',
      'Į keptuvę supilame likusį aliejų, įkaitiname ant silpnesnės vidutinės ugnies. Sudedame svogūną, papriką ir jelapeno pipirus. Kepiname, kol svogūnas suminkštėja. Tada sudedame česnaką ir visus prieskonius. Išmaišome ir kepiname ~2 min. Po to sudedame vištienos gabalėlius, išmaišome, supilame kokoso pieną ir kaitiname ~10-15 min., vis pamaišant. Pabaigoje įdedame druskos pagal skonį.',
      'Troškinį patiekite su ryžiais, tamsiomis pupelėmis ar kitu mėgstamu garnyru. Nepamirškite užbarstyti šviežia kalendra, skanaus!',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/without-watermark/22916.jpg',
    categories: [Category.Pietus, Category.Vakariene, Category.Vistiena],
  },
  {
    _id: 'sdfsdgfd51dfg416d1g5sdf5g',
    name: 'Avižiniai blyneliai su obuoliais',
    cookTime: CookTime['1 h'],
    ingredients: [
      '100g avižinių miltų',
      '250g migdolų pieno (arba paprasto pieno)',
      '125ml graikiško jogurto',
      '2vnt obuolių (didesnių arba 3 mažų)',
      '2 v.š. čija sėklų',
      '2 šaukšteliai kepimo miltelių',
      '1 šaukštelis vaniles ektrakto',
      '1 šaukštelis malto cinamono',
      '1/4 šaukštelio malto muskato',
      'druskos',
      'sviesto',
      'Patiekiant:',
      '1 vnt obuolio',
      'sviesto',
      'klevų sirupo',
      'malto cinamono',
      'graikinių riešutų',
    ],
    instructions: [
      'Visus produktus, išskyrus obuolius, sudėkite į plaktuvą ar dubenį, gerai išplakite iki vientisos tolygios tekstūros.',
      'Leiskite blynelių tešlai subręsti: palaikykite šaldytuve 30-60 minučių. Prieš kepdami dar kartą tešlą išmaišykite, sudėkite mažais kubeliais supjaustytus obuolius, vėl išmaišykite. Jeigu mėgstate saldžiau, galite tešlą pagal skonį pasaldinti (cukrumi, medumi, klevų sirupų ar kitu saldikliu).',
      ' Virš vidutinės ugnies įkaitintą keptuvę ištepkite sviestu. Pilkite po nedidelį samtelį tešlos, formuodami blynelius. Kepkite juos, kol blyneliai sutvirtės ir viršuje ims kilti burbuliukai. Tuomet atsargiai mentele apverskite ir apkepkite kitą pusę, kol bus gražiai auksinės spalvos.',
      'Patiekite su keptais obuoliais: keptuvėje ištirpinkite šaukštą sviesto, suberkite obuolių kubelius, supilkite klevų sirupą, suberkite cinamoną. Pakaitinkite virš vidutinės ugnies, kol obuoliai suminkštės.',
    ],
    servings: 1,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/without-watermark/29221.jpg',
    categories: [Category.Pusryciai, Category.Vakariene, Category.Vegetariska],
  },
  {
    _id: 'sdfsdgfd51dfg516d1g5df5g',
    name: 'Greitas "balandėlių" troškinys',
    cookTime: CookTime['1 h'],
    ingredients: [
      '450g maltos mėsos',
      '250g kopūstų',
      '150g ryžių',
      '1 morkos',
      '1 svogūno',
      '3 šaukštai pomidorų pastos',
      '2 šaukštai aliejaus',
      'druskos',
      'maltų juodųjų pipirų',
    ],
    instructions: [
      'Morką sutarkuoti stambia tarka arba supjaustyti plonais šiaudeliais. Kopūstą supjaustyti siaurais ruoželiais. Svogūną smulkiai sukapoti.1',
      'Puode, kuriame ruošite troškinį, iki vidutinės kaitros įkaitinti aliejų. Apkepinti svogūną, kol suminkštės. Apkepinti morką.',
      'Į puodą sudėti maltą mėsą. Vis pamaigant ir atskiriant mentele, kepinti, kol mėsa nebebus rausva.',
      ' Sudėti pomidorų pastą, kopūstą, ryžius. Išmaišyti ir dar kelias minutes pakepinti.',
      'Pilti maždaug 500 ml vandens, pagardinti prieskoniais. Uždengti dangčiu ir kaitrą sumažinti iki mažos kaitros.',
      'Troškinti apie 10 minučių, patikrinti, ar troškinyje dar liko skysčio, jei ne - įpilti dar šiek tiek vandens. Troškinti dar 10 - 15 minučių, kol ryžiai suminkštės.',
      ' Patiekiant tinka uždėti grietinės, pabarstyti žalumynais.',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/thumb920x573/31583.jpg',
    categories: [Category.Pietus, Category.Vakariene, Category.Kiauliena],
  },
  {
    _id: 'sdfsdgfd51dfg616d1g5df5g',
    name: 'Tortilijų suktinukai su vištiena, humusu ir daržovėmis',
    cookTime: CookTime['2 h'],
    ingredients: [
      'Vištienai:',
      '300 g vištos krūtinėlės',
      '1-2 valg. š. alyvuogių aliejaus',
      '¼ arb. š. druskos',
      '¼ arb. š. šviežiai grūstų juodųjų pipirų',
      '½ arb. š. džiovinto malto česnako',
      '½ arb. š. rūkytos paprikos',
      '1 arb. š. džiovintų prieskoninių žolelių',
      'Humusui:',
      '1 skardinės (240 g) konservuotų avinžirnių',
      '1 citrinos sulčių',
      '1 skiltelės česnako',
      '4 valg. š. sezamų sėklų pastos („Tahini“)',
      '2 valg. š. alyvuogių aliejaus',
      '½ arb. š. rūkytos paprikos',
      '½ arb. š. malto kumino',
      '½ arb. š. druskos',
      '¼ arb. š. grūstų juodųjų pipirų',
      'Tortilijoms:',
      '2 tortilijų',
      '4-6 valg. š. humuso',
      '4 valg. š. tarkuoto kietojo sūrio',
      '1 morkos',
      'poros saujų vyšninių pomidoriukų',
      '½ skardinės raudonųjų pupelių',
      'poros saujų špinatų (ar kitų žalių salotų)',
    ],
    instructions: [
      'Iškepkite vištieną. Orkaitę įkaitinkite iki 220 ᵒC temperatūros (kaitinimas iš viršaus ir apačios, be vėjelio). Vištienos krūtinėlę įtrinkite alyvuogių aliejumi ir prieskonių mišiniu. Kepkite įkaitintoje orkaitėje apie 20-25 minutes. Galite patikrinti termometru – vidinė iškepusios krūtinėlės temperatūra turi siekti 72 ᵒC. Jei norite, kad vištiena būtų labiau apskrudusi, paskutinėms porai kepimo minučių įjunkite grilio funkciją. Leiskite vištienai kelias minutes atvėsti, tuomet – supjaustykite riekelėmis ar gabalėliais.',
      'Paruoškite humusą. Avinžirnius nukoškite (nukoštą avinžirnių skystį išsaugokite puodelyje!), jų odeles nulukštenkite, kad sutrintas humusas būtų švelniausios tekstūros.',
      'Sudėkite į smulkintuvą visus humusui skirtus ingredientus. Sutrinkite iki vientisos masės. Papildomai įpilkite išsaugoto avinžirnių skysčio tiek, kad humusas būtų norimo tirštumo (gali prireikti kelių valgomųjų šaukštų).',
      'Paruoštą humusą sandariame indelyje šaldytuve galite laikyti iki savaitės.',
      'Paruoškite tortilijų suktinukus. Pasiruoškite visus ingredientus.',
      'Tortilijas aptepkite humusu, apiberkite smulkiai tarkuotu kietuoju fermentiniu sūriu ir smulkiai tarkuotomis šviežiomis morkomis. Išdėliokite perpus perpjautus vyšninius pomidoriukus, raudonąsias pupeles ir vištienos gabalėlius. Galiausiai uždėkite po saują špinatų ir susukite tortilijas į suktinukus.',
      'Tortilijų suktinukus tvirtai įtaisykite pietų dėžutėse.',
    ],
    servings: 2,
    imageUrl:
      'https://www.lamaistas.lt/uploads/editor/image/Tortilijos%20su%20humusu%20ir%20vistiena%20%2816%29%282%29.jpg',
    categories: [Category.Pietus, Category.Vakariene, Category.Vistiena],
  },
  {
    _id: 'sdfsdgfd51dsfdfg616d1g5df5g',
    name: 'Mango ir anakardžių glotnutis',
    cookTime: CookTime['15 min'],
    ingredients: [
      '1 mango',
      '1 banano',
      '50g špinatų',
      '50g anakardžių riešutų',
      '1 žalioji citrina',
      '1 šaukštas čija sėklų',
      'vandens/sulčių/augalinio pieno',
    ],
    instructions: [
      'Išspaudžiame citrinos sultis.',
      'Bananą ir mango nulupame. Supjaustome gabalėliais.',
      'Į virtuvinį kombainą dedame vaisius, riešutus, špinatus, beriame sėklas, pilame citrinų sultis ir vandens, kad beveik apsemtų ir susmulkiname iki vientisos konsistencijos masės.',
    ],
    servings: 2,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/without-watermark/29870.jpg',
    categories: [Category.Pusryciai, Category.Smuciai, Category.Uzkandziai],
  },
  {
    _id: 'sdfsdgfd51dsfdaffg616d1gs5df5g',
    name: 'Jogurtinis kokteilis su vaisiais',
    cookTime: CookTime['15 min'],
    ingredients: [
      '3 persikų (šviežių ar konservuotų)',
      '200g ananasų (šviežių ar konservuotų)',
      '200g graikiško jogurto arba migdolų pieno',
      '1 banano',
    ],
    instructions: [
      'Persikus nulupti, išimti kauliukus ir supjaustyti. Ananasą nulupti, supjaustyti. Bananą nulupti ir supjaustyti.',
      'Visus vaisius ir jogurtą sudėti į elektrinį plaktuvą ir suplakti iki vientisos konsistencijos masės.',
    ],
    servings: 2,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/thumb920x573/35666.jpg',
    categories: [Category.Pusryciai, Category.Smuciai, Category.Uzkandziai],
  },
  {
    _id: 'sdfsdgfd51dsfdaffg616d1lg5df5g',
    name: 'Mango smoothie',
    cookTime: CookTime['15 min'],
    ingredients: ['1 mangas', '1 banananas', 'Augalinio arba karvės pieno', 'Graikiško jogurto'],
    instructions: ['Viską sudėti ir suplakti.'],
    servings: 2,
    imageUrl:
      'https://i2.wp.com/www.downshiftology.com/wp-content/uploads/2018/08/Mango-Smoothie-4-2.jpg',
    categories: [Category.Pusryciai, Category.Smuciai, Category.Uzkandziai],
  },
  {
    _id: 'sdfsdgfd51dsfdaffg6h16d1g5df5g',
    name: 'Makaronai su vištiena ir daržovėmis',
    cookTime: CookTime['30 min'],
    ingredients: [
      '300 g viso grūdo makaronų',
      '250 g vištienos filė',
      '200 g grietinėlės (30 % rieb.)',
      '170 g konservuotų kukurūzų',
      '150 g kietojo sūrio',
      '125 g morkų',
      '100 g šaldytų žirnelių',
      '35 g sviesto',
      'žiupsnelio druskos, juodųjų pipirų, ciberžolės, česnako granulių',
      '115 g vandens',
    ],
    instructions: [
      'Makaronus išvirti pagal instrukciją ant pakuotės.',
      'Vištienos krūtinėlę supjaustyti kubeliais, morkas supjaustyti kubeliais ir pakepinti keptuvėje su įkaitintu svisetu 5-8 min. Įberti druską, pipirus, ciberžolę, česnako granules. Sudėti šaldytus žirnelius, konservuotus kukurūzus. Troškinti 10-15 min.',
      'Pilti grietinėlę, berti tarkuotą sūrį. Troškinti apie 10-15 min.',
    ],
    servings: 2,
    imageUrl:
      'https://sveikataipalankus.lt/wp-content/uploads/2023/01/Viso-gr%C5%ABdo-makaronai-su-dar%C5%BEovi%C5%B3-vi%C5%A1tienos-ir-grietin%C4%97l%C4%97s-pada%C5%BEu-2-scaled.jpg',
    categories: [Category.Pietus, Category.Vakariene, Category.Vistiena],
  },
  {
    _id: 'sdfsdgfd51dsfdaffg616swd1gs5df5g',
    name: 'Poke bowl',
    cookTime: CookTime['15 min'],
    ingredients: ['lašiša', 'ridikėliai', 'edamame pupelės', 'agurkai', 'avokadas', 'ryžiai'],
    instructions: [
      'Lašiša iškepti, ryžius išvirti, visa kitą supjaustyti ir patiekti bendroje lėkšteje.',
    ],
    servings: 2,
    imageUrl: 'https://www.lamaistas.lt/uploads/editor/image/lasisos-poke-bowl.jpg',
    categories: [Category.Pietus, Category.Zuvis, Category.Vegetariska],
  },
  {
    _id: 'sdfsdgfd51dsfdaffg6163swd1gs5sdf5g',
    name: 'Raugintų kopūstų sriuba pagirioms',
    cookTime: CookTime['30 min'],
    ingredients: [
      '2.5l jautienos sultinio',
      '1kg raugintų kopūstų',
      '1kg rūkytų kiaulienos šonkauliukų',
      '1 aitrioji paprika (nebūtinai)',
      '3-5 skiltelės česnako',
      'druskos, maltų juodųjų pipirų',
    ],
    instructions: [
      'Didelio pasiruošimo net ir nereikia. Tiesiog iš vakaro (neapgaudinėkite savęs – juk žinote kur einate ir kuo visa tai gali pasibaigti) pasiruoškite visus produktus ir pasidėkite į šaldytuvą.',
      'Užkaiskite puodą su sultiniu, supjaustykite šonkaulius po vieną, sultiniui užvirus sudėkite į jį šonkaulius, kopūstus ir papriką. Jeigu turėsite – įmeskite ir kelis džiovintus grybus. Aromatas bus gardesnis.',
      'Uždenkite puodą dangčiu, palaukite kol vėl užvirs ir sumažinkite ugnį iki minimalios. Palikite viską ramiai pukšėti gerą valandą, kol mėsa lengvai atsiskirs nuo kaulų.',
      'Išjunkite ugnį, išimkite papriką, berkite druską ir maltus pipirus (negailėdami) ir per česnako trintuvę sutraiškykite česnako skilteles. Jeigu tokios neturite – kuo smulkiau jas sukapokite ir meskite į sriubą.',
      'Uždenkite sriubą dangčiu ir kvieskite visus prie stalo. Kol visi susirinks, kol susės – sriuba spės šiek tiek pabręsti.',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/without-watermark/36576.jpg',
    categories: [Category.Pietus, Category.Sriubos, Category.Kiauliena],
  },
  {
    _id: 'sdfsdgfd512dsfdaffg6163swd1gs5sdf5g',
    name: 'Kreminiai makaronai',
    cookTime: CookTime['1 h'],
    ingredients: [
      '250g makaronų',
      '1kg raugintų kopūstų',
      '150 vyšninių pomidorų',
      '200ml vandens (arba sultinio - bus dar skaniau)',
      '120ml	grietinėlės, 30% riebumo',
      '1 sauja kietojo sūrio',
      '5 skiltelės česnako',
      '1 sauja baziliko',
      '0.5 šaukštelio aitriųjų paprikų',
      '1 šauktas alyvuogių aliejaus',
    ],
    instructions: [
      'Į keptuvę arba puodą pilti aliejų, įkaitinti.',
      'Į įkaitintą keptuvę įspausti arba smulkiai supjaustyti česnako skilteles, kepti apie 30 sekundžių.',
      'Į keptuvę su česnakais suberti vyšninius pomidorus, išmaišyti ir uždengti su dangčiu. Pomidorus kepti ant vidutinės kaitros, kol jie šiek tiek suminkštės, tada švelniai ir atsargiai suspausti su mentele, kad kiek subliukštų ir išskirtų savo sultis.',
      'Tuomet į keptuvę su pomidoriukais berti aitriųjų paprikų dribsnius, druską, pipirus, suberti orzo makaronus ir išmaišyti, kad tolygiai pasikirstytų prieskoniai ir skystis.',
      'Maišant supilti vandenį ir visą laiką maišyti, kol sutirštės.',
      'Tada supilti grietinėlę ir maišant virti nedidelės kaitros apie 5-10 m arba iki makaronai suminkštės (jei imtų traukti prie dugno, įpilkite dar šiek tiek vandens arba sultinio).',
      'Suberti tarkuotą sūrį, truputį pasmulkintus bazilikus, išmaišyti.',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/without-watermark/26938.jpg',
    categories: [Category.Pietus, Category.Vakariene, Category.Vegetariska],
  },
  {
    _id: 'sdfs1dgfd512dsfdaffsg6163swd1gs5sdf5g',
    name: 'Trupiniuotis',
    cookTime: CookTime['>3 h'],
    ingredients: [
      '1 skardinė kondensuoto pieno saldinto arba Rududu',
      'riešutų (nebūtina)',
      'aguonų (nebūtina)',
      '500g kvietinių miltų',
      '200g sviesto',
      '200g	grietinės, 30% riebumo',
      '2vnt kiaušinių (didelių arba 3 mažų)',
      '1 šauktštelis kepimo miltelių',
      '1 šaukštelis vanilinio cukraus (nebūtina)',
      'žiubsnelis druskos',
    ],
    instructions: [
      'Į dubenį beriame miltus, kepimo miltelius, vanilinį cukrų ir žiupsnelį druskos, išmaišome.',
      'Į miltus per burokinę tarką įtarkuojame šaltą sviestą. Tarkuodami vis užberiame miltus ant sviesto, lengvai pamaišome, jog tarkiai pasidengtų miltais.',
      'Įmušame kiaušinius, sukrečiame grietinę. Pirmiausia išmaišome šaukštu, o tada rankomis greitais judesiais užminkome vientisą tešlą. Ją suspaudžiame į rutulį, tada paplokštiname į diską, įvyniojame į maistinę plėvelę ir padedame į šaldytuvą 1-2 valandoms sutvirtėti.',
      'Jei naudojate kondensuotą pieną, skardinę dėkite į puodą, kad apsemtų bent per 10 cm. Virkite ant nedidelės kaitros apie 2-2.5 val nuo užvirimo. Išimkite ir palikite atvėsti. Jei naudojate Rududu, virti nereikia, aš jį tik pašildau virš garų vonelės prieš pat maišant su trupiniais.',
      'Įkaitiname orkaitę iki 180C (kaitinimas viršus+apačia, be ventiliatoriaus). Didelę kepimo skardą išklojame kepimo popieriumi.',
      'Atšalusią tešlą padaliname į 5 dalis. Imame po vieną dalį (kitas paliekame šaldytuve) ir burokine sutarkuojame ant skardos tolygiu sluoksniu.',
      'Kepame iki 180C įkaitintos orkaitės viduryje iki trupiniai gražiai pagels (man trunka apie 15-20 min). Kadangi krašteliai ruduoja greičiau už vidurį, po daugmaž 10-12 min aš mentele pavartau, palaužau ir nustumiu labiau parudavusius trupinius į centrą.',
      'Iškepusius trupinius su visu kepimo popieriumi perkeliame ant grotelių atvėsti. Pravėsusius sulaužome į mažus gabalėlius.',
      'Taip iškepame visą tešlą.',
      'Iškepusius trupinius sumaišome su pasmulkintais riešutais ir aguonomis. Sukrečiame virtą kondensuotą pieną (arba pašildytą Rududu) ir gerai išmaišome, jog visi trupiniai tolygiai pasidengtų. Jei naudojate Rududu ir maišant masė atrodo sausoka (su Rududu taip gali nutikti, jis tirštesnis už virtą kondensuotą peiną), papildomai įdėkite šaukštą grietinės arba medaus ar šlakelį brendžio ar apelsinų sulčių ar kito skystesnio paskaninimo.',
      'Norimos formos (naudojau apvalią 20cm) dugną ir kraštus išklojame kepimo popieriumi arbai maistine plėvele. Sukrečiame trupinių masę ir standžiai suspaudžiame, kad neliktų tarpų.',
      'Dedame į šaldytuvą bent 6-8 valandoms (o dar geriau - nakčiai), kad trupiniuotis sutvirtėtų.',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/thumb920x573/37358.jpg',
    categories: [Category.Desertai],
  },
  {
    _id: 'pgfd512dsfdaffsg6163swd1gs5sdf5g',
    name: 'Pupelių sriuba',
    cookTime: CookTime['1 h'],
    ingredients: [
      '1 stiklinė pupelių',
      '4vnt bulvių',
      '1 morka',
      '1 svogūnas',
      '50g šaltai rūkytos šonines',
      '3 skilt česnako',
      '1 pundelis krapų',
      '0.5 šaukšt miltų',
      '2 vnt lauro lapų',
      '1 žiubsnelis maltų juodųjų pipirų',
      'drusko',
      '2l vandens',
    ],
    instructions: [
      'Pupeles užpilame vandeniu ir paliekame brinkti. Aš dažniausiai palieku per naktį, o kitą dieną verdu sriubą.',
      'Bulves supjaustome kubeliais, svogūną ir česnaką pasmulkiname, morką supjaustome griežinėliais, šoninę nedideliais kubeliais.',
      'Pupeles ir bulves suberiame į puodą, užpilame vandeniu, sudedame lauro lapus, suberiame pipirus ir užkaičiame sriubą virti.',
      'Keptuvėje pakepiname šoninę, kol ji apskrus. Tada sudedame svogūną ir morkas. Pakepiname šiek tiek, kol daržovės suminkštės. Sudedame česnaką. Suberiame miltus. Gerai išmaišome. Sudedame keptuvės turinį į puodą.',
      'Pabaigiame virti sriubą. Pagardiname druska ir smulkintais krapais.',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/without-watermark/28913.jpg',
    categories: [Category.Sriubos],
  },
  {
    _id: 'pgfd512dsffrdaffsg6163swd1gs5sdf5g',
    name: 'Spagečiai Carbonara',
    cookTime: CookTime['1 h'],
    ingredients: [
      '300g spagečių',
      '150g šoninės',
      '120g kietojo sūrio',
      '4vnt kiaušinių trynių',
      '1 žiubsnelis maltų juodųjų pipirų',
      'druskos',
    ],
    instructions: [
      'Sūrį smulkiai sutarkuoti. Trynius sudėti į mažą dubenį, lengvai paplakti, kad susijungtų.',
      'Pančetą (arba šoninę) supjaustyti vidutinio dydžio kubeliais. Dėti į sausą įkaitintą keptuvę ir maišant pakepinti ant vidutinės kaitros iki gabalėlių krašteliai ims darytis kiek traškesni. Keptuvę su pančeta nuimti nuo kaitros, kad truputį atvėstų (liktų šilta, bet nebūtų karšta, tad nesutrauktų trynių).',
      'Kol kepa pančeta, užkaisti virti spagečius. Virti dosniai pasūdytame vandenyje pagal instrukciją ant pakuotės iki spagečiai bus al dente.',
      'Į trynių plakinį įmaišyti tarkuotą sūrį ir žiupsnį pipirų. Tada įpilti 1-2 v.š. riebalų nuo apkepintos pančetos ir įdėti daugmaž trečdalį pačios pančetos, išmaišyti.',
      'Išvirus spagečiams juos iškart nukošti (stiklinę virimo skysčio pasilikti).',
      'Dar karštus (būtinai!) spagečius sudėti į keptuvę su pančeta. Sudėti kiaušinių bei sūrio mišinį, įpilti daugmaž samtį spagečių virimo vandens ir greitais judesiais išmaišyti. Jei atrodo per sausa, įpilti dar šiek tiek spagečių virimo vandens.',
      'Tiekti iškart. Tiekiant galima pabarstyti tarkuotu kietuoju sūriu ir žiupsneliu šviežiai maltų juodųjų pipirų.',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/thumb920x573/37840.jpg',
    categories: [Category.Pietus, Category.Vakariene],
  },
  {
    _id: 'pgfd512dsffrdaffsg6163juswd1gs5sdf5g',
    name: 'Jautienos troškinys',
    cookTime: CookTime['>3 h'],
    ingredients: [
      '800g brandintos jautienos',
      '1 a.š. druskos',
      '1/2 a.š. pipirų',
      'aliejaus kepimui',
      '1 svogūno',
      '4 skilt česnako',
      '3 morkų',
      '2 v.š. miltų',
      '1 v.š. vorčesterio padažo',
      '2 v.š. pomidorų pastos',
      '150ml sauso raudonojo vyno',
      '750ml jautienos sultinio',
      '1 a.š. čiobrelio',
      '3 lauro lapų',
    ],
    instructions: [
      'Kambario temperatūros jautieną pabarstome druska ir pipirais.',
      'Ketaus puode arba keptuveje įkaitiname aliejų ir ant didelės kaitros apskrudiname jautienos gabalėlius iš abiejų pusių po 3 minutes, kol kraštai stipriai apskrunda. Jautieną kepame per 2 kartus, nes svarbu, kad skrudinami gabaleliai per daug nesiliestų tarpusavyje - kitu atveju mėsa ne apskrus, o troskinsis. Apskrudintą jautieną atidedame.',
      'Apkepiname svoguno griežinėlius, smulkintą česnaką ir morkų griežinėlius kelias minutes.',
      'Ant daržovių beriame miltų, išmaišome',
      'Dedame vorčesterį ir pomidorų pastą. Išmaišome.',
      'Supilame vyną ir gerai pakaitiname ant didelės kaitros keletą minučių, kad nugaruotų.',
      'Supilame sultinį, į puodą gražiname jautieną, beriame čiobrelio, lauro lapų, išmaišome.',
      'Troškiname uždengus dangčiu ant nedideles kaitros, kad padažas vos burbuliuotų, 1,5 val.',
      'Po pusantros valandos suberiame stambiais 16 gabalėliais pjaustytas bulves ir troškiname ant nedidelės kaitros (kad padažas vos burbuliuotų) be dangčio dar valandą.',
      'Patiekiant pabarstome petražolėmis. Skanu valgyti su šaukstu graikiško jogurto ar grietinės - sušvelnina skoni.',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/without-watermark/38846.jpg',
    categories: [Category.Pietus, Category.Vakariene],
  },
  {
    _id: 'pgfd512dsffrdaffs443g6163juswd1gs5spdf5g',
    name: 'Šonkauliai',
    cookTime: CookTime['>3 h'],
    ingredients: [
      'kelios vaismedžių rūkymo kaladėlių',
      'kiaules šonkaulių',
      'popamies ar porkmafia prieskonių',
      '1/2 a.š. pipirų',
      'Cherry & Cola bbq padažo',
    ],
    instructions: [
      'Ištrint su prieskoniais ir palaikyt 30min vieną pusę, 30min kitą pusę',
      'Užkurt kamado iki 130 laipsnių. Ties 100 laipsnių pridaryt sklendes (palikt 3 apatines). Sumest rūkymo kaladėles.',
      'Sulaukus 130 laipsnių įmest susuktus ir persmeigtus šonkaulius. Kept 1.5-2val (priklausomai nuo šonkaulių storio).',
      'Po poros val ištraukt ir kišt kiekvieną į foliją. Vėl susukus stačiom įmest į kamado valandai.',
      'Po valandos traukt iš folijos. Riebiai ištept bbq padažu.',
      'Pakelt iki 150 laipsnių ir sumest šonkaulius pusvalandžiui, kad padažas prikeptų.',
    ],
    servings: 4,
    imageUrl: 'https://www.lamaistas.lt/uploads/modules/recipes/thumb920x573/35009.jpg',
    categories: [Category.Grill],
  },
];
