import { FormControl, InputLabel, MenuItem, Select as SelectComponent } from '@mui/material';

export interface Item {
  value: string;
  label: string;
}
interface Props {
  className?: string;
  value?: string;
  items: Item[];
  label: string;
  onChange: (value: string) => void;
}

export const Select = ({ className, value, items, label, onChange }: Props) => {
  return (
    <FormControl fullWidth className={className}>
      <InputLabel id="simple-select-label">{label}</InputLabel>
      <SelectComponent
        labelId="simple-select-label"
        id="simple-select"
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value)}
      >
        {items.map((x) => (
          <MenuItem key={x.value} value={x.value}>
            {x.label}
          </MenuItem>
        ))}
      </SelectComponent>
    </FormControl>
  );
};
