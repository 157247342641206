export enum Category {
  Grill = 'Grill',
  Sriubos = 'Sriubos',
  Pietus = 'Pietūs',
  Pusryciai = 'Pusryčiai',
  Vakariene = 'Vakarienė',
  Kiauliena = 'Kiauliena',
  Zuvis = 'Žuvis',
  Koses = 'Košės',
  Vistiena = 'Vištiena',
  Uzkandziai = 'Užkandžiai',
  UzkandziaiVakareliams = 'Užkandžiai vakarėliams',
  Smuciai = 'Smūčiai',
  Vegetariska = 'Vegetariška',
  Desertai = 'Desertai',
}
