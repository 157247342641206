import { IconButton, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface Props {
  className?: string;
  onChange: (value: string) => void;
}

export const Search = ({ className, onChange }: Props) => {
  return (
    <Paper
      component="form"
      className={className}
      sx={{ p: '0 4px', display: 'flex', alignItems: 'center' }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => onChange(e.target.value)}
      />
      <IconButton sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
