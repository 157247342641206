import { ReactNode } from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';

interface Props {
  className?: string;
  items: { id: string; name: string }[];
  selectedItems: string[];
  onSetSelectedItems: (value: string[]) => void;
  renderValue?: (selected: string[]) => ReactNode;
}

export const MultiSelect = ({
  className,
  items,
  selectedItems,
  onSetSelectedItems,
  renderValue,
}: Props) => {
  return (
    <FormControl className={className} sx={{ width: '100%' }}>
      <InputLabel id="category-input-label">Category</InputLabel>
      <Select
        labelId="category-input-label"
        id="category-input"
        multiple
        value={selectedItems}
        onChange={(e) => {
          const {
            target: { value },
          } = e;
          onSetSelectedItems(typeof value === 'string' ? value.split(',') : value);
        }}
        input={<OutlinedInput label="Category" />}
        renderValue={renderValue}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              width: 250,
            },
          },
        }}
      >
        {items.map((x) => (
          <MenuItem key={x.id} value={x.id}>
            <Checkbox checked={selectedItems.indexOf(x.id) > -1} />
            <ListItemText primary={x.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
