import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecipes } from 'context/RecipesContext';
import { Recipe } from 'api/Recipe';
import { Category } from 'api/data/categories';

export const getRecipesCount = (recipes: Recipe[], categoryId: Category) =>
  recipes.filter((x) => x.categories?.includes(categoryId)).length;

export const Categories = () => {
  const navigate = useNavigate();
  const { recipes } = useRecipes();

  const categories = Object.values(Category);

  return (
    <Container component="main" sx={{ marginTop: '5rem', marginBottom: '3rem' }}>
      <Box sx={{ marginTop: '3rem' }}>
        <Typography component="h1" variant="h3" sx={{ textAlign: 'center' }}>
          Categories
        </Typography>
        {categories.length ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((category) => (
                  <TableRow
                    key={category}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => navigate(`/recipes?categoryId=${category}`)}
                      sx={{ cursor: 'pointer' }}
                    >
                      {category} ({getRecipesCount(recipes, category)})
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Box>
    </Container>
  );
};
