import { UserType } from 'api/User';

interface AuthenticationState {
  accessToken: string;
  userType: UserType;
}

const AUTH_SESSION_STORAGE_KEY = 'auth';

export type Error = {
  message: string;
};

export const saveAuthState = (state: AuthenticationState) => {
  localStorage.setItem(AUTH_SESSION_STORAGE_KEY, JSON.stringify(state));
};

export const getAuthState = (): AuthenticationState | undefined => {
  const authStateString = localStorage.getItem(AUTH_SESSION_STORAGE_KEY);
  if (!authStateString) {
    return undefined;
  }
  return JSON.parse(authStateString);
};

export const clearAuthState = () => {
  localStorage.removeItem(AUTH_SESSION_STORAGE_KEY);
};
