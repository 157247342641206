import { Category } from 'api/data/categories';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

interface Context {
  categories: Category[];
  onCategoriesChange: (value: Category[]) => void;
}

const CategoriesContext = createContext<Context | null>(null);

export const CategoriesProvider = ({ children }: { children: ReactNode }) => {
  const [categories, setCategories] = useState<Category[]>([]);

  const onCategoriesChange = useCallback((value: Category[]) => {
    setCategories(value);
  }, []);

  return (
    <CategoriesContext.Provider value={{ categories, onCategoriesChange }}>
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategories = () => {
  const categoriesContext = useContext(CategoriesContext);
  if (!categoriesContext) {
    throw new Error('useCategories must be used within a CategoriesProvider');
  }
  return categoriesContext;
};
