import { Box, Chip, Container, List, ListItem, ListItemText, Typography } from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { People as PeopleIcon, AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { useRecipes } from 'context/RecipesContext';

import s from './Recipe.module.css';

const RecipeDetails = () => {
  const params = useParams<{ id?: string }>();
  const navigate = useNavigate();

  const { recipes } = useRecipes();
  const recipe = recipes.find((x) => x._id === params.id);

  if (!recipe) {
    return <p>Recipe was not found...</p>;
  }

  return (
    <Container component="main" sx={{ marginTop: '5rem', marginBottom: '3rem' }}>
      <Typography component="h1" variant="h3" sx={{ textAlign: 'center' }}>
        {recipe.name}
      </Typography>
      {recipe.categories && (
        <Box className={s.categories}>
          {recipe.categories.map((category) => (
            <Chip
              key={category}
              label={category}
              onClick={() => navigate(`/recipes?categoryId=${category}`)}
              sx={{ marginRight: '15px', marginBottom: '6px' }}
            />
          ))}
        </Box>
      )}
      {recipe.imageUrl && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
          <img
            src={recipe.imageUrl}
            alt={recipe.name}
            width="600"
            height="400"
            className={s.recipeImage}
          />
        </Box>
      )}
      {recipe.description && (
        <Typography sx={{ textAlign: 'center', marginTop: '2rem', marginBottom: 0 }} paragraph>
          {recipe.description}
        </Typography>
      )}
      <Box sx={{ display: 'flex', maxWidth: '600px', margin: '20px auto 0' }}>
        <Box sx={{ width: '50%', marginRight: '70px' }}>
          <Typography component="h1" variant="h5">
            Ingredients
          </Typography>
          {recipe.servings && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PeopleIcon sx={{ marginRight: '5px' }} />
              <p>Servings: {recipe.servings}</p>
            </Box>
          )}
          {recipe.ingredients && (
            <List>
              {recipe.ingredients.map((x, i) => (
                <ListItem key={i} sx={{ paddingLeft: 0 }}>
                  <ListItemText primary={x.trim()} />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        <Box sx={{ width: '50%' }}>
          <Typography component="h1" variant="h5">
            Instructions
          </Typography>
          {recipe.cookTime && (
            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
              <AccessTimeIcon sx={{ marginRight: '5px' }} />
              <p>Cook time: {recipe.cookTime}</p>
            </Box>
          )}
          {recipe.instructions && (
            <List>
              {recipe.instructions.map((x, i) => (
                <ListItem key={i} sx={{ paddingLeft: 0 }}>
                  <ListItemText primary={`${i + 1}. ${x.trim()}`} />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export { RecipeDetails as Recipe };
