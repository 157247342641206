import { useEffect, useMemo, useState } from 'react';
import { Box, Container, Typography, Divider } from '@mui/material';
import { RecipeCard } from 'components/RecipeCard';
import { useSearchParams } from 'react-router-dom';
import { useRecipes } from 'context/RecipesContext';
import { Recipe } from 'api/Recipe';
import { Search } from 'components/Search';
import { Select } from 'components/Select';

import s from './Recipes.module.css';
import { MultiSelect } from 'components/MultiSelect';
import { Category } from 'api/data/categories';
import { getRecipesCount } from 'routes/Categories';
import { CookTime } from 'api/data/cookTime';

const getActiveCategories = (categories: Category[], selectedCategoriesIds: string[]) =>
  selectedCategoriesIds.reduce((result: Category[], value) => {
    const category = categories.find((category) => category === value);
    if (category) {
      result.push(category);
    }
    return result;
  }, []);

const filterRecipes = (
  recipes: Recipe[],
  categoriesIds: string[],
  searchInput: string,
  cookTime?: string,
) => {
  let filteredRecipes = [...recipes];

  if (categoriesIds.length) {
    const filteredByCategories = filteredRecipes.filter((x) => {
      if (x.categories) {
        const hasSelectedCategory = x.categories.some(
          (category) => categoriesIds.indexOf(category) > -1,
        );
        return hasSelectedCategory;
      }
      return false;
    });

    filteredRecipes = filteredByCategories;
  }

  if (searchInput) {
    const lowerCaseInput = searchInput.toLowerCase();
    filteredRecipes = filteredRecipes.filter(
      (x) =>
        x.name.toLowerCase().includes(lowerCaseInput) ||
        x.description?.toLowerCase().includes(lowerCaseInput) ||
        x.ingredients?.some((ing) => ing.toLowerCase().includes(lowerCaseInput)),
    );
  }

  if (cookTime && cookTime !== 'all') {
    filteredRecipes = filteredRecipes.filter((x) => x.cookTime === cookTime);
  }

  return filteredRecipes;
};

export const Recipes = () => {
  const { recipes } = useRecipes();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedCookTime, setSelectedCookTime] = useState<string | undefined>();

  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get('categoryId');

  useEffect(() => {
    if (categoryId) {
      setSelectedCategories([categoryId]);
    }
  }, [categoryId]);

  const filteredRecipes = useMemo(
    () => filterRecipes(recipes, selectedCategories, searchInput, selectedCookTime),
    [recipes, searchInput, selectedCategories, selectedCookTime],
  );

  return (
    <Container component="main" sx={{ marginTop: '5rem', marginBottom: '3rem' }}>
      <Box>
        <Box sx={{ marginBottom: '1.5rem' }}>
          <Typography component="h1" variant="h3" sx={{ textAlign: 'center' }}>
            Recipes ({filteredRecipes.length})
          </Typography>
        </Box>
        <Box className={s.filters}>
          <Search className={s.searchInput} onChange={(value) => setSearchInput(value)} />
          <MultiSelect
            className={s.categoriesSelect}
            items={Object.values(Category).map((category) => ({
              id: category,
              name: `${category} (${getRecipesCount(recipes, category)})`,
            }))}
            selectedItems={selectedCategories}
            onSetSelectedItems={(value) => setSelectedCategories(value)}
            renderValue={(selected) =>
              getActiveCategories(Object.values(Category), selected).join(', ')
            }
          />
          <Select
            className={s.cookTimeSelect}
            label="Cook Time"
            onChange={(value) => setSelectedCookTime(value)}
            items={[
              { value: 'all', label: 'All' },
              ...Object.values(CookTime).map((x) => ({ value: x, label: x })),
            ]}
            value={selectedCookTime}
          />
        </Box>
        <Divider />
        <div className={s.recipes}>
          {filteredRecipes.reverse().map((x) => (
            <RecipeCard key={x._id} recipe={x} />
          ))}
        </div>
      </Box>
    </Container>
  );
};
