import { useEffect } from 'react';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import { useNavigate, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { clearAuthState } from 'utils/authUtils';
import { Recipe } from 'api/Recipe';
import { useRecipes } from 'context/RecipesContext';

export const Layout = () => {
  const navigate = useNavigate();

  const fetchedRecipes = Recipe.getAll();

  const { onRecipesChange } = useRecipes();

  useEffect(() => {
    if (fetchedRecipes) {
      onRecipesChange(Recipe.getAll());
    }
  }, [fetchedRecipes, onRecipesChange]);

  const onLogout = () => {
    clearAuthState();
    navigate('/login');
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              <Button variant="text" color="inherit" onClick={() => navigate('/')}>
                Recipes
              </Button>
              <Button variant="text" color="inherit" onClick={() => navigate('/categories')}>
                Categories
              </Button>
            </Box>
            <Button color="inherit" onClick={() => onLogout()}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <ToastContainer />
      <Outlet />
    </div>
  );
};
