import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, CssBaseline, TextField, Box, Typography, Container } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { saveAuthState } from 'utils/authUtils';

const CREDENTIALS = {
  username: 'nerijus',
  password: 'labasasbruksnys',
  token: 'super-epic-token-hash',
};

interface Errors {
  username: string;
  password: string;
}

export const Login = () => {
  const [errors, setErrors] = useState<Errors>({ username: '', password: '' });
  const navigate = useNavigate();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');

    if (!username) {
      setErrors({ ...errors, username: 'Username is required' });
      return;
    }

    if (!password) {
      setErrors({ ...errors, password: 'Password is required' });
      return;
    }

    if (username === CREDENTIALS.username) {
      saveAuthState({ accessToken: CREDENTIALS.token, userType: 'Admin' });
      navigate('/');
    } else {
      setErrors({ ...errors, password: 'Invalid credentials' });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="User"
            name="username"
            autoComplete="username"
            autoFocus
            error={!!errors.username}
            helperText={errors.username}
            onChange={() => setErrors({ ...errors, username: '' })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={!!errors.password}
            helperText={errors.password}
            onChange={() => setErrors({ ...errors, password: '' })}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
